import * as SentrySvelte from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';

if (!dev) {
	SentrySvelte.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		release: __VERSION__,
		tracesSampleRate: parseFloat(import.meta.env.VITE_TRACE_SAMPLE_RATE),
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		integrations: [new SentrySvelte.BrowserTracing(), new SentrySvelte.Replay()]
	});

	SentrySvelte.setTag('svelteKit', 'browser');
}

// This will catch errors in `load` functions from `+page.ts` files
export const handleError = (({ error, event }) => {
	if (!dev) {
		SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } });
	}
	const message = error instanceof Error ? error.message : 'An error occurred';
	return {
		message
	};
}) satisfies HandleClientError;
